import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faSpotify} from '@fortawesome/free-brands-svg-icons';



export default function HomePage() {
    return (
        <div className="HomePage">
            <header className="hero">
                <h1 className="hero-text">
                    Hi, I am
                    <span> Andres Rios.</span>
                </h1>
                <p className="h-sub-text">A Junior Software Developer from Oxnard, California.</p>
                <div className="icons">
                    <a rel="noreferrer" href="https://github.com/Arios39" target="_blank" className="icon-container">
                    <FontAwesomeIcon icon={faGithub} className="icon gh"/>
                    </a>
                    <a rel="noreferrer" href="https://www.linkedin.com/in/arios39/" target="_blank" className="icon-container">
                    <FontAwesomeIcon icon={faLinkedin} className="icon ln"/>
                    </a>
                    <a rel="noreferrer" href="https://open.spotify.com/user/andres_rios_6?si=7b30b56f88c04e1e" target="_blank" className="icon-container">
                    <FontAwesomeIcon icon={faSpotify} className="icon sp"/>
                    </a>
                    
                </div>
            </header>
        </div>
    )
}
