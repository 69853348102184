import React from 'react'
import ContactItem from '../Components/ContactItem'
import phone from '../img/icons/phone.svg';
import email from '../img/icons/emailme.svg';
import Title from '../Components/Title'


function ContactPage() {
    return (
       <div>
           <div className="title">
           <Title title={'Contact Me'} span={'Contact Me'}/>
           </div>
            <div className="ContactPage">
            <div className="contact-sect">                
                <ContactItem icon={phone} text={"+1 (805) 822-2179"} title={'Phone'} textref={'tel:+18058222179'}/>
                <ContactItem icon={email} text={"arios39@ucmerced.edu"} title={'Email'} textref={'mailto:arios39@ucmerced.edu'}/>
            </div>
        </div>
       </div>
    )
}

export default ContactPage
