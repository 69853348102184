import React from 'react'
import ImageSection from '../Components/ImageSection'
import SkillsSection from '../Components/SkillsSection'
import Title from '../Components/Title'

function AboutPage() {
    return (
        <div className="AboutPage">
            <Title title={'About Me'} span={'About Me'}/>
            <ImageSection/>
            <Title title={'My skills'} span={'My skills'}/>
            <div className="skillsContainer">
                <SkillsSection skill={'Python'} progress={'90%'}/>
                <SkillsSection skill={'Javascript'} progress={'80%'}/>
                <SkillsSection skill={'React JS'} progress={'60%'}/>
                <SkillsSection skill={'Node JS'} progress={'50%'}/>
                <SkillsSection skill={'SQL'} progress={'80%'}/>
                <SkillsSection skill={'C/C++'} progress={'70%'}/>
                <SkillsSection skill={'Swift'} progress={'60%'}/>
                <SkillsSection skill={'Java'} progress={'60%'}/>
                <SkillsSection skill={'x86 assembly'} progress={'60%'}/>
            </div>


        </div>
    )
}

export default AboutPage
