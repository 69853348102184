import port1 from '../img/port/UC-Merced-pdcm.png';
import port2 from '../img/port/Physical-Planning-ucmerced.png';
import port3 from '../img/port/space-invaders.jpeg';
import port4 from '../img/port/Delicato-Capstone.png';
import port5 from '../img/port/Lyrics-Extension.png';
import port6 from '../img/port/GRE-Vocab-Extension.png';
import port7 from '../img/port/Youtube-emotions.png';
import port8 from '../img/port/Computer-Networks.png';
import port9 from '../img/port/Zillow-chrome-extension.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faGoogle} from '@fortawesome/free-brands-svg-icons';


const portfolios = [
    {
        id: 1,
        category: 'Javascript',
        link: 'https://dc.ucmerced.edu/',
        icon: <FontAwesomeIcon icon={faGoogle} className="icon gh"/>,
        image: port1,
        title: 'Planning, Design, & Construction Management'
        },
    {
        id: 2,
        category: 'Javascript',
        link: 'https://planning.ucmerced.edu/',
        icon: <FontAwesomeIcon icon={faGoogle} className="icon gh"/>,
        image: port2,
        title: 'Physical & Environmental Planning'
    },
    {
        id: 3,
        category: 'Python',
        link: 'https://github.com/Arios39/Space_Invaders',
        icon: <FontAwesomeIcon icon={faGithub} className="icon gh"/>,
        image: port3,
        title: 'Space Invaders Clone'
    },
    {
        id: 4,
        category: 'Swift',
        link: 'https://github.com/Arios39/Capstone',
        icon: <FontAwesomeIcon icon={faGithub} className="icon gh"/>,
        image: port4,
        title: 'Delicato Family Vineyards Capstone'
    },
    {
        id: 5,
        category: 'React JS',
        link: 'https://github.com/Arios39/Lyrics-chrome-extension',
        icon: <FontAwesomeIcon icon={faGithub} className="icon gh"/>,
        image: port5,
        title: 'Lyrics Chrome Extension'
    },
    {
        id: 6,
        category: 'React JS',
        link: 'https://github.com/Arios39/GRE-extension',
        icon: <FontAwesomeIcon icon={faGithub} className="icon gh"/>,
        image: port6,
        title: 'GRE Vocab Chrome Extension'
    },
    {
        id: 7,
        category: 'Python',
        link: 'https://github.com/Arios39/Youtube-Emotion-Detection',
        icon: <FontAwesomeIcon icon={faGithub} className="icon gh"/>,
        image: port7,
        title: 'Youtube Emotion Detection'
    },
    {
        id: 8,
        category: 'C',
        link: 'https://github.com/Arios39/Computer-Networks',
        icon: <FontAwesomeIcon icon={faGithub} className="icon gh"/>,
        image: port8,
        title: 'Chat Client & Server Client Server Implementation'
    },
    {
        id: 9,
        category: 'Typescript',
        link: 'https://github.com/Arios39/zillow-chrome-extension/tree/master/Documents/Dev/Chrome-Extensions/Zillow-chrome-extension',
        icon: <FontAwesomeIcon icon={faGithub} className="icon gh"/>,
        image: port9,
        title: 'Zillow Chrome Extension'
    }





]
export default portfolios;