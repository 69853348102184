import React from 'react'
import about from '../img/about.JPG';
import Resume from '../Docs/Andres_Rios_Resume.pdf';
function ImageSection() {
    return (
        <div className="ImageSection">
            <div className="img">
                <img src={about} alt=""/>
            </div>
            <div className="about-info">
                <h4> I am <span>Andres Rios,</span></h4>
                <p className="about-text">
                a software developer who believes in the power of technology to help empower people. 
                I began my journey in software development when I join UC Merced as a student assistant where I helped develop and maintain the Department of Planning, Design, & Construction Management websites. 
                After Graduating from UC Merced I hope to continue my journey to empowering people through the use of software.
                My biggest accomplishment to date would be the development of a react chrome extension that helped my friends better prepare for the GRE.
                I am always coming up with new ideas and would love to share some of them.
                </p>
                <div className="about-details">
                    <div className="left-section">
                    <p>Name:</p>
                    <p>Age:</p>
                    <p>Education:</p>
                    <br/>
                    <p>Languages:</p>
                    <p>Hometown:</p>
                    </div>

                    <div className="right-section">
                    <p> Andres Rios</p>
                    <p> 22</p>
                    <p> <em>B.S.</em> Computer Science & Engineering  <em>University of California, Merced</em></p>
                    <p> English, Spanish, French</p>
                    <p> Oxnard, California</p>
                    </div>

                </div>
                <form method="get" action={Resume}>
                <button className="btn">Download Resume</button>
                </form>
            </div>
            
        </div>
    )
}

export default ImageSection
