import React,{useState} from 'react'
import Categories from '../Components/Categories'
import Title from '../Components/Title'
import MenuItems from '../Components/MenuItems'
import portfolios from'../Components/allportfolios'

const allCategories = ['All', ...new Set(portfolios.map(item => item.category))];

function PortfolioPage() {
    const [categories, setCategories] = useState(allCategories);
    //change null to portfolio array later
    const [menuItems, setMenuItems] = useState(portfolios);

    const filter = (category) =>{
        if(category === 'All'){
            setMenuItems(portfolios)
            return;
        }
        const filteredData  = portfolios.filter((item)=>{
            return item.category === category;
        })
        setMenuItems(filteredData);
    }

    return (
        <div className="PortfolioPage">
            

             <div className="title">
             <Title title={'Portfolio'} span={'Portfolio'}/>
             </div>
             <div className="portfolios-data">
                 <Categories filter={filter} categories={categories} />
            
                 <MenuItems menuItem={menuItems}/>

             </div>
        </div>
    )
}

export default PortfolioPage
